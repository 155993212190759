<template>
      <ToggleMode ref="ToggleMode" @darkmode="isdark"  v-if='ws_mode'/>
  <div>
    <LanguageSelector  :wsmode=ws_mode :tmode=t_mode :mmode=m_mode v-if='ws_mode && showTop' />
    <div :class="isScrolled || type=='sticky'? 'navbar sticky' : 'navbar'" v-if="ws_mode && showTop" >
    <!-- style="{ width: isScrolled ? '80%' : '90%' }" -->
      <div class="navbar-item" :class="isActive('home')?'navbar-item menu-active': 'navbar-item default'">
        <!-- <a href="home" @click="toActive('home')">
          <MDIcon :icon='homeIcon' />
          <img :src="require(`../../../public/assets/icons/${homeIcon}.png`)" height="23px" class="mdi_icon" :icon='homeIcon' alt="">
          {{  $t('home') }}
        </a> -->
        <router-link @click="toActive('home')" to="/home"><MDIcon :icon='homeIcon' />  {{  $t('home') }} </router-link>

      </div>
      <!-- <div class="navbar-item" :class="iconActive=='homes'?'navbar-item menu-active': 'navbar-item default'">
        <a href="home" @click="toActive('homes')">
          <img :src="require(`../../../public/assets/icons/${homeIcon}.png`)" height="23px" class="mdi_icon" :icon='homeIcon' alt="">
          {{  $t('home') }}
        </a>
      </div> -->
      <div class="navbar-item" :class="isActive('service')?'navbar-item menu-active': 'navbar-item default'">
        <!-- <a href="services" @click="toActive('service')">
          <MDIcon :icon='serviceIcon' />

        <img :src="require(`../../../public/assets/icons/${serviceIcon}.png`)" width="36px" class="mdi_icon" :icon='serviceIcon' alt="">
        {{  $t('servicesMenu') }}
        </a> -->
        <router-link @click="toActive('service')" to="/service"><MDIcon :icon='serviceIcon' :icwidth=30 />  {{  $t('servicesMenu') }} </router-link>
      </div>
      <div class="navbar-item" :class="isActive('career')?'navbar-item menu-active': 'navbar-item default'">
        <!-- <a href="careers" @click="toActive('career')">
          <MDIcon :icon='careerIcon' />

        <img :src="require(`../../../public/assets/icons/${careerIcon}.png`)" width="23px" class="mdi_icon" :icon='careerIcon' alt="">
        {{  $t('careers') }}
        </a> -->
        <router-link @click="toActive('career')" to="/career"><MDIcon :icon='careerIcon' :icwidth=21 />  {{  $t('careers') }} </router-link>

      </div>
      <!-- <div class="navbar-item" :class="isActive('menu')?'navbar-item menu-active': 'navbar-item default'">
        <router-link @click="toActive('menu')" to="/menu"> Fooder </router-link>
      </div> -->
    <div style="width: 100px"></div>
      <div class="navbar-item" :class="'navbar-item primary-active'">
        <!-- <a href="about" @click="toActive('about')">
          <MDIcon :icon='aboutIcon' />

        <img :src="require(`../../../public/assets/icons/${aboutIcon}.png`)" height="24px" class="mdi_icon" :icon='aboutIcon' alt="">
        {{  $t('about') }}
        </a> -->
        <router-link @click="toActive('getTheApp')" to="/getTheApp"><MDIcon :icon='getTheAppIcon' />  {{  $t('getTheApp') }} </router-link>

      </div>
    </div>
  </div>
</template>

<script>
import LanguageSelector from './LanguageSelector.vue';
import MDIcon from './MDIcon.vue';
import ToggleMode from './ToggleMode.vue';

export default {
  name: 'NavbarBox',
  props: {
    type: {
      type: String,
      default: 'default'
    },
    light: {
      type: Boolean,
      default: true
    }
  },
  components:{
    LanguageSelector,
    ToggleMode,
    MDIcon
  },
  data() {
    return {
      dark:false,
      isScrolled: false,
      screenWidth: window.innerWidth

    };
  },
  computed: {
    
    homeIcon() {
      return this.$route.path === '/home' ? 'active-home' : 'home';
    },
    serviceIcon() {
      return this.$route.path === '/service' ? 'active-hand' : 'hand';
    },
    careerIcon() {
      return this.$route.path === '/career' ? 'active-career' : 'career';
    },
    aboutIcon() {
      return this.$route.path === '/about' ? 'active-about' : 'about';
    },
    getTheAppIcon() {
      return 'mdi_download';
    },
    // currentPath() {
    //   return this.$route.path === '/' ? 'home' : this.$route.path.replace('/', '');
    // },
    // Vérifie si le chemin actuel est celui de la route passée
    isActive() {
      return (routeName) => {
        if(this.$route.path=='/rider'||this.$route.path=='/fooder')
        {
          return `/${routeName}` === '/career';
        }
        return this.$route.path === `/${routeName}`;
      };
    },
    showTop() {
      let url = ['/importCsv','/accounts.google.com/signin/oauth/oauthchooseaccount','/menu/','/menu','/menu-fake','/menu/add','/menu/edit', '/menu/delete','/stock','/stock/add','/history/stock','/history','/profile','/performance','/performance/rider','/order/report','/order/delivering',];
      return !url.includes(this.$route.path);
    },
    ws_mode() {
      return this.screenWidth > 1270; // Comparaison avec un nombre
    },
    t_mode() {
      return ( this.screenWidth < 1270); // Comparaison avec un nombre
    }
  },
  mounted() {
    
    // this.setDefaultActive();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
  
    callChildMethod() {
      // Appelle la méthode du composant enfant via la référence
      this.$refs.ToggleMode.updateIconPaths();
    },
    isdark(darkmode) {
      this.dark = darkmode;
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 10;
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    toActive(element) {
      // console.log(this.$route.path, this.active);
      // console.log(this.homeIcon);
      // this.iconActive = element//.replace('/','');
      // this.homeIcon = ( element == 'home' || element == '' )?'active-home':'home';
      // this.serviceIcon = ( element == 'service')?'active-hand':'hand';
      // this.careerIcon = ( element == 'career')?'active-career':'career';
      // this.aboutIcon = ( element == 'about')?'active-about':'about';
      // this.callChildMethod(this.dark);

        this.$emit('activateSection', element);
    
      // this.callChildMethod(this.dark);
    },
  },
};
</script>

<style scoped>

.menu-active{
  background: var(--primary-container);
  font-weight: bold;
  color: var(--on-surface);
}
/* .navbar>div a ,  */
.default a{
  color: var(--secondary);
  opacity: 0.8;
}
.navbar>div a ,
.navbar-item {
  height: 100%;
  width: 100%;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

}
.navbar-item a:focus {
  font-weight: bold;
  color: var(--on-surface);
  /* font-size: 24px; */
}
.navbar-item a:hover {
  text-shadow: 0 4px 4px var(--darkcyan-25);

}

.navbar {
  width: 65%;
  z-index: 100;
  /* 
  font-size: 24px;
  height: 100px; */
  font-size: 18px;
  height: 64px;
  /* background-color: #3498db; */
  background: var(--surface-bright);
  position: absolute;
  position: fixed;
  top: 12px;
  left: 50%;
  transform: translateX(-50%);
  /* padding: 0 20px; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: width 0.3s ease-in-out, top 0.3s ease-in-out, position 0.3s ease-in-out;
  border-radius: 100px;
}

.sticky {
  box-shadow: 0 4px 4px var(--dark-color-25);
  width: 55%;
  position:fixed;
  top: 0;
  /* font-size: 20px; */
  font-size: 16px;

}
.primary-active, 
.primary-active a,
.primary-active a:focus{
  background: var(--on-surface);
  color: var(--surface-bright);
}
.primary-active a:focus{
  font-weight: normal;
}
</style>
