import { createApp } from 'vue'
import App from './App.vue'
// import i18n from './locales/i18n';
import i18n from "./locales";

// import { create } from 'core-js/core/object';
import VueScrollTo from 'vue-scrollto';

import router from './router/router'; // Import du router
import VueLazyload from 'vue-lazyload';

// import firebase from 'firebase/app';
// import 'firebase/auth'; // Si tu utilises l'authentification Firebase
// import { getAuth } from 'firebase/auth';
// Configuration Firebase
// const firebaseConfig = {
//     apiKey: "AIzaSyB86vY0ZsimOJP-Bo9zW8hFhZ4l1xmXNVE",
//     authDomain: "ridee-app-f0895.firebaseapp.com",
//     projectId: "ridee-app-f0895",
//     storageBucket: "ridee-app-f0895.appspot.com",
//     messagingSenderId: "547016938628",
//     appId: "1:547016938628:web:abcdefg12345", // Remplace avec ton vrai App ID
//   };
  
  // Initialisation de Firebase
//   const appFirebase = initializeApp(firebaseConfig);

  // Initialisation de l'authentification Firebase
//   const auth = getAuth(appFirebase);
//   firebase.initializeApp(firebaseConfig);
  
// createApp(App).mount('#app')
const app = createApp(App);
app.use(i18n);
app.use(router);
app.use(VueScrollTo);
app.use(VueLazyload);
app.mount('#app');

