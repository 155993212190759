<!-- App.vue -->
<template>
   <!-- <nav>
      <router-link to="/">Home</router-link>
      <router-link to="/about">About</router-link>
    </nav> -->
    <!-- <router-view></router-view> -->
  <!-- <skeleton-loader width="100%" height="150px" v-if="isLoading" class="skeleton"  /> -->
    <!-- <div v-if="isLoading"></div> -->
  <div id='top' @touchstart.passive="onTouchStart" @touchmove.passive="onTouchMove">
    <!-- <TryDrag/> -->
    <!-- <MDIcon icon='about' />
    <MDIcon icon='active-about' />
    <MDIcon icon='active-career' />
    <MDIcon icon='active-hand' />
    <MDIcon icon='active-home' />
    <MDIcon icon='arrow' />
    <MDIcon icon='career' />
    <MDIcon icon='hand' />
    <MDIcon icon='home' /> 
    <MDIcon icon='mdi_career_pri' />
    <MDIcon icon='mdi_delivery' />
    <MDIcon icon='mdi_economic' />
    <MDIcon icon='mdi_hand_coin_pri' />
    <MDIcon icon='mdi_hand_coin' />
    <MDIcon icon='mdi_hourglass' />
    <MDIcon icon='mdi_idea' />
    <MDIcon icon='mdi_lightbulb' />-->
 
    <div>
      <NavbarBox v-show="notlogin" :wsmode=ws_mode :tmode=t_mode :mmode=m_mode :type="['home', 'about'].includes(currentComponent)? 'default': 'sticky'" @activateSection="handleSectionChange" />    
    </div>
    <!-- <ToggleMode ref="ToggleMode" @darkmode="isdark" /> -->

  <div v-show="!ws_mode && showMenuBurger">
    <!-- <MenuBurger :wsmode=ws_mode :tmode=t_mode :mmode=m_mode @activateSection="handleSectionChange" ref="menuBurger" /> -->
  </div>
  <!-- <nav>
      <button @click="currentComponent = 'service'">Services</button>
    </nav> -->
    <!-- <div v-if="currentComponent=='home'">
      <Row @activateSection="handleSectionChange" />
    </div> -->
    <!-- Affichage conditionnel -->
    <!-- <component :is="currentComponentComponent"></component> -->
    <router-view @call-parent-method="handleScroll" :wsmode=ws_mode :tmode=t_mode :mmode=m_mode></router-view>

  </div>
</template>

<script>
// import SkeletonLoader from 'vue-skeleton-loader';
// import AboutUs from './AboutUs.vue';
// import BentoBox404 from './BentoBox404.vue';
// import FAQChat from './FAQChat.vue';
// import FormBox from './FormBox.vue';
// import MDIcon from './MDIcon.vue';
// import MenuBurger from './MenuBurger.vue';
import NavbarBox from './NavbarBox.vue';
// import Row from './Row.vue';
// import Services from './Services.vue'
// import TryDrag from './TryDrag.vue';
// import ToggleMode from './ToggleMode.vue';

export default {
  name: 'BentoPage',
  components:{
    // TryDrag,
    // MDIcon,
    // AboutUs,
    // FormBox,
    // SkeletonLoader,
    // MenuBurger,
    NavbarBox,
    // Row,
    // Services,
    // BentoBox404,
    // FAQChat
    // ToggleMode,
  },
  data() {
    return {
      isLoading: true,
      screenWidth: window.innerWidth,
      currentComponent: 'home',  // Composant par 
      touchStartY: 0,
      touchEndY: 0,
    }
  },
  computed: {
    notlogin(){
      let url = ['/accounts.google.com/signin/oauth/oauthchooseaccount'];
      return !url.includes(this.$route.path);
    },
    // currentComponentComponent() {
      // if (this.currentComponent === 'service') {
      //   return Services;
      // } 
      // else if (this.currentComponent === 'faq') {
      //   return FAQChat;
      // } 
      // else if (this.currentComponent === 'home') {
      //   return null;
      // } 
      // else if (this.currentComponent === 'career') {
      //   return FormBox;
      // } 
      // else if (this.currentComponent === 'about') {
      //   return AboutUs;
      // } 
      // else{
      //   return BentoBox404;
      // }
    // },
    showMenuBurger() {
      return this.$route.path!='/accounts.google.com/signin/oauth/oauthchooseaccount';
    },
    ws_mode() {
      return (this.screenWidth > 1270); 
    },
    t_mode() {
      return (this.screenWidth <= 1270 && this.screenWidth>773); 
    },
    m_mode() {
      return (this.screenWidth <= 773 ); 
    }
  },
  mounted() { //Cycle de vie du composant.
    // this.$nextTick(() => {
    //   this.handleScroll(); 
    // });
    
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
    setTimeout(() => {
      this.isLoading = false;
    }, 3000); 
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    handleScroll() {
      // Récupérer la hauteur du viewport
      // const windowHeight = window.innerHeight;
      const bentos = document.querySelectorAll('.bento');
      bentos.forEach((bento, i) => {
        setTimeout(() => {
          this.checkVisibility(bento);
        }, 
        (i * 10)+0); 
      });
      // Vérifier la position des éléments
      // this.$refs.box1 && this.checkVisibility(this.$refs.box1);
      // this.$refs.box2 && this.checkVisibility(this.$refs.box2);
      // this.$refs.box3 && this.checkVisibility(this.$refs.box3);
    },
    checkVisibility(element) {
      const rect = element.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        element.classList.add('visible');
      }
    },
    handleResize() {
      this.screenWidth = window.innerWidth;
    },
    handleSectionChange(componentName) {
      // Met à jour le composant en fonction de la valeur renvoyée
      // this.handleScroll();  // Appelle handleScroll une fois le composant chargé

      this.currentComponent = componentName; // 'row' dans ton cas
      // Utilise nextTick pour attendre que le DOM soit mis à jour
      this.$nextTick(() => {
          this.handleScroll();  // Appelle handleScroll une fois le composant chargé
        });
    },
    onTouchStart(event) {
      if(!this.ws_mode && this.showMenuBurger) this.$refs.menuBurger.startTouch(event); // Appelle la méthode dans l'enfant
    },
    onTouchMove(event) {
      if(!this.ws_mode && this.showMenuBurger) this.$refs.menuBurger.moveTouch(event); // Appelle la méthode dans l'enfant
    },
  }
}
</script>
<style scoped>
#top{
  padding-top: 0.1px;
}
.skeleton {
  background-color: #e0e0e0; /* Couleur de fond gris clair */
  border-radius: 4px; /* Coins arrondis */
  animation: pulse 1.5s infinite; /* Animation de pulsation */
}

@keyframes pulse {
  0% {
    background-color: #e0e0e0;
  }
  50% {
    background-color: #f0f0f0; /* Couleur un peu plus claire */
  }
  100% {
    background-color: #e0e0e0;
  }
}

</style>