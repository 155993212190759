import { createRouter, createWebHistory } from 'vue-router';

const Row = () => import('@/components/bento/Row.vue'); // Lazy load
const AboutUs = () => import('@/components/bento/AboutUs.vue'); // Lazy load
const Services = () => import('@/components/bento/Services.vue'); // Lazy load
// const FormBox1 = () => import('@/components/bento/ImageCropper.vue'); // Lazy load
// const FormBox1 = () => import('@/components/chart/SeeCombine.vue'); // Lazy load
// const FormBox1 = () => import('@/components/chart/ViewerChart.vue'); // Lazy load
// const FormBox1 = () => import('@/components/dashboard/MainDashPage.vue'); // Lazy load
const FormBox1 = () => import('@/components/bento/FormBox1.vue'); // Lazy load jerenaa
const FormBox2 = () => import('@/components/bento/FormBox2.vue'); // Lazy load
// const FormBox2 = () => import('@/components/util/FaceDetecter.vue'); // Lazy load
// const FormBox2 = () => import('@/components/util/VehicleDetector.vue'); // Lazy load
// const FormBox2 = () => import('@/components/util/FoodDetector.vue'); // Lazy load
const FormBox3 = () => import('@/components/bento/FormBox3.vue'); // Lazy load
const FAQChat = () => import('@/components/bento/FAQChat.vue'); // Lazy load
const AppLandingPage = () => import('@/components/bento/AppLandingPage.vue'); // Lazy load
const DagoLandingPage = () => import('@/components/bento/DagoLandingPage.vue'); // Lazy load
const RiderLandingPage = () => import('@/components/bento/RiderLandingPage.vue'); // Lazy load
const FooderLandingPage = () => import('@/components/bento/FooderLandingPage.vue'); // Lazy load
const BentoBox404 = () => import('@/components/bento/BentoBox404.vue'); // Lazy load
// const GoogleLogin = () => import('@/components/util/google/GoogleLogin.vue'); // Lazy load
// const MainDashPage = () => import('@/components/dashboard/MainDashPage.vue'); // Lazy load

const routes = [
  { path: '/', redirect: '/home' },
  { path: '/home', component: Row },
  { path: '/faq', component: FAQChat },
  { path: '/about', component: AboutUs },
  { path: '/service', component: Services },
  { path: '/career', component: FormBox1 },
  { path: '/rider', component: FormBox2 },
  { path: '/fooder', component: FormBox3 },
  { path: '/getTheApp', component: AppLandingPage },
  { path: '/getDagoApp', component: DagoLandingPage },
  { path: '/getRiderApp', component: RiderLandingPage },
  { path: '/getFooderApp', component: FooderLandingPage },
  // { path: '/menu', component: MainDashPage },
  // { path: '/menu/add', component: MainDashPage },
  // { path: '/menu/edit', component: MainDashPage },
  // { path: '/menu/delete', component: MainDashPage },
  // { path: '/stock', component: MainDashPage },
  // { path: '/stock/add', component: MainDashPage },
  // { path: '/history/stock', component: MainDashPage },
  // { path: '/history', component: MainDashPage },
  // { path: '/profile', component: MainDashPage },
  // { path: '/performance', component: MainDashPage },
  // { path: '/performance/rider', component: MainDashPage },
  // { path: '/order/report', component: MainDashPage },
  // { path: '/order/delivering', component: MainDashPage },
  // { path: '/importCsv', component: MainDashPage },
  // { path: '/accounts.google.com/signin/oauth/oauthchooseaccount', component: GoogleLogin },
  { path: "/:catchAll(.*)", component: BentoBox404 },

];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
